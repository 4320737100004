import { SportsRugbySharp } from '@mui/icons-material';
import React from 'react'
const dataUrl = 'https://baileybrukettadotcomsourcedata.s3.us-east-2.amazonaws.com/currently_learning.json'
class Home extends React.Component{
    constructor(props){
        super(props);
        this.setLoadedData   = this.setLoadedData.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
        this.state        = {
            loadedData: null,
            errorMessage: null
        }
    }
    setLoadedData(a){this.setState({loadedData: a})}
    setErrorMessage(a){this.setState({errorMessage: a})}
    componentDidMount(){
        this.load();
    }
    async load(){
        try{
            console.log(dataUrl);
            const response = await fetch(dataUrl);
            const result = await response.json()
            if(response.ok){
                this.setLoadedData(result);
            }else{
                this.setErrorMessage(result.message);
            }
        }catch(error){
            this.setErrorMessage(error.message);
        }
    }
    render(){
        if(this.state.loadedData){
            const objectList = [];
            let insert = <div></div>;
            for(let i = 0; i < this.state.loadedData["stuff"].length; i++){
                objectList.push(
                    <li>{this.state.loadedData["stuff"][i]}</li>
                )
            }
            if(objectList.length > 0){
                insert = 
                <div style={{ position: "fixed",
                bottom: "0", alignItems: "center", right: "0",
                backgroundColor: "rgba(255,180,0,.5)", borderRadius:"25%",
                padding: "1%", lineHeight: "20px"}}>
                    <p>Currently working on:</p>
                    <ul>{objectList}</ul>
                </div>
            }
            return (
                <div>
                    <h1 >Hey</h1>
                    <p   >Welcome to my website</p>
                    <p >Click the menu (in the corner)</p>
                  
                </div>
            );
        }    else{
            return (
                <div>
                    <h1 >Hey</h1>
                    <p  >Welcome to my website</p>
                    <p >Click the menu (in the corner)</p>

                </div>
            );
        }
    }
}

export class HomeTitle extends React.Component{
    render(){
        return(
            <p style={{position: 'fixed', left: 0}}>Home</p>
        );
    }
}

export default Home
