import React from 'react'
import styles from './bigcss.css'
class Phone extends React.Component{
    render(){
        return (
            <div >
                <h1>Phone</h1>
                <p >(309) 259 0896</p>
            </div>
            
        );
    }
}
export default Phone