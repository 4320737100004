import React from 'react'
import styles from './bigcss.css'
const dataUrl = 'https://baileybrukettadotcomsourcedata.s3.us-east-2.amazonaws.com/mobile_apps.json'
class MobileApps extends React.Component{
    constructor(props){
        super(props);
        this.setLoadedData   = this.setLoadedData.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
        this.state        = {
            loadedData: null,
            errorMessage: null
        }
    }
    setLoadedData(a){this.setState({loadedData: a})}
    setErrorMessage(a){this.setState({errorMessage: a})}
    componentDidMount(){
        this.load();
    }
    async load(){
        try{
            console.log(dataUrl);
            const response = await fetch(dataUrl);
            const result = await response.json()
            if(response.ok){
                this.setLoadedData(result);
            }else{
                this.setErrorMessage(result.message);
            }
        }catch(error){
            this.setErrorMessage(error.message);
        }
    }
    render(){
        if(this.state.loadedData){
            const objectList = [];
            for(let i = 0; i < this.state.loadedData["stuff"].length; i++){
                const jobName = this.state.loadedData["stuff"][i]["job"];
                const jobTitle = this.state.loadedData["stuff"][i]["title"];
                const startDate = this.state.loadedData["stuff"][i]["start"];
                const endDate   = this.state.loadedData["stuff"][i]["end"];
                const works = this.state.loadedData["stuff"][i]["work"];
                const skills = this.state.loadedData["stuff"][i]["skills"];
                const media  = this.state.loadedData["stuff"][i]["media"];
                const workList = [];
                const skillList = [];
                const mediaList = [];
                for(let workIndex = 0; workIndex < works.length; workIndex++){
                    workList.push(<li 
                        className="jobExperienceItem"
                        key={workIndex}
                    
                    >{works[workIndex]}</li>)
                }
                for(let skillIndex = 0; skillIndex < skills.length; skillIndex++){
                    skillList.push(<li
                        className="skillItem"
                        key={skillIndex}
                        >{skills[skillIndex]}</li>)
                }
                for(let mediaIndex = 0; mediaIndex < media.length; mediaIndex++){
                    if(media[mediaIndex].includes(".mp4")){
                        mediaList.push(
                        <li className='mediaItem'>
                        <video controls style={{
                            width: "30%",
                            borderRadius: "5%"
                        }}>
                           <source src={media[mediaIndex]}/> 
                        </video>
                        </li>)
                    }else{
                        mediaList.push(
                        <li className='mediaItem'>
                            <img style={{
                                width: "30%",
                                borderRadius: "5%",   
                            }} src={media[mediaIndex]}>
                      
                            </img>
                        </li>
                    )    
                    }
                }
                objectList.push(<div 
                    className = "workexperiencecontainer"
                    key={i}
                    >

                    <p className="jobName">{jobName}</p>
                    
                    <div className="titlebar">
                        <p className="jobTitle">{jobTitle}</p>

                        <p className="startdate">{startDate}</p>
                        <p className="enddate"  >{endDate}</p>
                    </div>

                    
                    
                    <div className="experienceAndContainerWrapper">
                        <ul className="jobExperience">{workList}</ul>
                        <ul className="skillContainer">{skillList}</ul>
                    </div>

                 
                        <ul className="mediaContainer">{mediaList}</ul>
              
               
                    </div>);
            }
            return(
                <div className = "wholeworkexperiencecontainer">
                    {objectList}
                </div>
               
            );
        }else if (this.state.errorMessage){
            return(
                <div>
                    <p style={{ textAlign: 'center' }}>{this.state.errorMessage}</p>
                </div>
            )
        }
        return (
            <p>Loading</p>
        );
    }
}
export default MobileApps