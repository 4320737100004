import React from 'react'
import styles from './bigcss.css'
class About extends React.Component{
    render(){
        return (
            <div >
                <h1>About</h1>
                <p >This serverless React application was deployed with AWS Lambda and AWS Cloudformation</p>
            </div>
            
        );
    }
}
export default About