// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState } from "react";
import ProductList from "./components/ProductList";
import config from "./config.json";
import axios from "axios";
import MainPage from './Components/MainPage.js'
import Home from './Components/Home.js';
import './App.css';

const colors = {
  PRIMARY_COLOR: '#F17F2A',
  SECONDARY_COLOR: '#9d8f85',
  BORDER_COLOR: '#d1c9c3',
  BACKGROUND_COLOR: '#FAF4F0',
  GOOD_ORANGE: '#F17F2A'
}

const App = ({ isSSR, ssrData }) => {
  const [err, setErr] = useState(false);
  const [result, setResult] = useState({ loading: true, products: null });
  useEffect(() => {
    document.body.style.backgroundColor = colors.BACKGROUND_COLOR;
    //const getData = async () => {
    //  const url = config.SSRApiStack.apiurl;
    //  try {
    //    let result = await axios.get(url);
    //    setResult({ loading: false, products: result.data });
    //  } catch (error) {
    //    setErr(error);
    //  }
   // };
   // getData();
  }, []);
  //if (err) {
 //   return <div>Error {err}</div>;
  //} else {
    return (
      <div className="App" >
        <div>
          < MainPage MyComponent={<Home/>} /> 
        </div>
      </div>
    );
 // }
};

export default App;
