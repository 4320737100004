import React from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CabinIcon from '@mui/icons-material/Cabin';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import SchoolIcon from '@mui/icons-material/School';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Route, Link, BrowserRouter, Routes } from 'react-router-dom'  

import Home from '../Components/Home'
import WorkExperience from '../Components/WorkExperience'
import MobileApps from '../Components/MobileApps'
import WebApps from '../Components/WebApps'
import OtherProjects from '../Components/OtherProjects'
import Education from '../Components/Education'
import About from '../Components/About'
import Email from '../Components/Email'
import Phone from '../Components/Phone'

import TitleText from '../Components/TitleText'
import WorkExperienceTitle from '../Components/WorkExperience'
import MobileAppsTitle from '../Components/MobileApps'
import WebAppsTitle from '../Components/WebApps'
import OtherProjectsTitle from '../Components/OtherProjects'
import EducationTitle from '../Components/Education'
import AboutTitle from '../Components/About'
import "./MainPage.css"

import { textAlign } from '@mui/system';

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Rotate90DegreesCcw } from '@mui/icons-material';

const colors = {
    PRIMARY_COLOR: '#F17F2A',
    SECONDARY_COLOR: '#9d8f85',
    BORDER_COLOR: '#d1c9c3',
    BACKGROUND_COLOR: '#FAF4F0',
    GOOD_RED: '#f22929'
}

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
    }),
  );
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  export default function MainPage({MyComponent}) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
  
    return (
      <BrowserRouter >
      
        <Box sx={{ display: 'flex' }} >
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{background: '#ced6e6'}}>
          <Toolbar>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
                <Routes>
                    <Route path="/"               element={<TitleText titleText="Home"/>}/>
                    <Route path="/WorkExperience" element={<TitleText titleText="Work Experience"/>}/>
                    <Route path="/MobileApps"     element={<TitleText titleText="Mobile Apps"/>}/>
                    <Route path="/WebApps"        element={<TitleText titleText="Web Apps"/>}/>
                    <Route path="/OtherProjects"  element={<TitleText titleText="Other Projects"/>}/>
                    <Route path="/Education"      element={<TitleText titleText="Education"/>}/>
                    <Route path="/About"          element={<TitleText titleText="About"/>}/>
                    <Route path="/Email"      element={<TitleText titleText="Email"/>}/>
                    <Route path="/Phone"          element={<TitleText titleText="Phone"/>}/>
                </Routes>
            </Typography>
            <IconButton
              style={{
                  
                  color: "#585c64"
                  
              }}
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
            </AppBar>
            <Main open={open}>
          <DrawerHeader />
      
          <Routes>
                    <Route path="/"               element={<Home />}/>
                    <Route path="/WorkExperience" element={<WorkExperience/>}/>
                    <Route path="/MobileApps"     element={<MobileApps/>}/>
                    <Route path="/WebApps"        element={<WebApps/>}/>
                    <Route path="/OtherProjects"  element={<OtherProjects/>}/>
                    <Route path="/Education"      element={<Education/>}/>
                    <Route path="/About"          element={<About/>}/>
                    <Route path="/Email"          element={<Email/>}/>
                    <Route path="/Phone"          element={<Phone/>}/>
          </Routes>
          
            </Main>
            <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemIcon><CabinIcon style={{backgroundColor: 'black', color: 'white', borderRadius: '25%'}}/></ListItemIcon>
                    <ListItemText primary="Home"/>
                </ListItem>
                <ListItem button component={Link} to="/WorkExperience">
                    <ListItemIcon><CardTravelIcon style={{backgroundColor: 'black', color: 'white', borderRadius: '25%'}}/></ListItemIcon>
                    <ListItemText primary="Work Experience"/>
                </ListItem>
                <ListItem button component={Link} to="/MobileApps">
                    <ListItemIcon><AppShortcutIcon style={{backgroundColor: 'black', color: 'white', borderRadius: '25%'}} /></ListItemIcon>
                    <ListItemText primary="Mobile Apps"/>
                </ListItem>
                <ListItem button component={Link} to="/WebApps">
                    <ListItemIcon><LaptopMacIcon style={{backgroundColor: 'black', color: 'white', borderRadius: '25%'}}/></ListItemIcon>
                    <ListItemText primary="Web Apps"/>
                </ListItem>
                <ListItem button component={Link} to="/OtherProjects">
                    <ListItemIcon><ModeEditIcon style={{backgroundColor: 'black', color: 'white', borderRadius: '25%'}}/></ListItemIcon>
                    <ListItemText primary="Other Projects"/>
                </ListItem>
                <ListItem button component={Link} to="/Education">
                    <ListItemIcon><SchoolIcon style={{backgroundColor: 'black', color: 'white', borderRadius: '25%'}}/></ListItemIcon>
                    <ListItemText primary="Education"/>
                </ListItem>
                <ListItem button component={Link} to="/About">
                    <ListItemIcon><QuestionMarkIcon style={{backgroundColor: 'black', color: 'white', borderRadius: '25%'}}/></ListItemIcon>
                    <ListItemText primary="About"/>
                </ListItem>
            </List>

          <Divider/>

          <List>
           
            <ListItem button  button component={Link} to="/Email">
                <ListItemIcon><MailIcon /></ListItemIcon>
                <ListItemText primary="Email" />
            </ListItem>
            <ListItem button  button component={Link} to="/Phone">
                <ListItemIcon><PhoneEnabledIcon /></ListItemIcon>
                <ListItemText primary="Phone" />
            </ListItem>
            <ListItem button component="a" href="https://github.com/BaileyBruketta">
                <ListItemIcon><GitHubIcon /></ListItemIcon>
                <ListItemText primary="Github" />
            </ListItem>
            <ListItem button component="a" href="https://www.linkedin.com/in/bailey-bruketta-1581b1190/">
                <ListItemIcon><LinkedInIcon /></ListItemIcon>
                <ListItemText primary="Linkedin" />
            </ListItem>

          </List>
          <Divider />

            </Drawer>
        </Box>
       
        <div style={{
            zIndex: -1,
            width: "30%",
            height: "3px",
            borderRadius: "0%", 
            backgroundColor: colors.SECONDARY_COLOR, 
            left: 0,
            bottom: ".25%",
            display: "flex",
            position: "fixed"}}           
            />
            <div style={{
                zIndex: -1,
            width: "33%",
            height: "3px",
            borderRadius: "0%", 
            backgroundColor: colors.SECONDARY_COLOR, 
            left: 0,
            bottom: ".75%",
            display: "flex",
            position: "fixed"}}           
            />
            <div style={{
                zIndex: -1,
            width: "36%",
            height: "3px",
            borderRadius: "0%", 
            backgroundColor: colors.SECONDARY_COLOR, 
            left: 0,
            bottom: "1.25%",
            display: "flex",
            position: "fixed"}}           
            />
            <div style={{
                zIndex: -1,
            width: "30%",
            height: "3px",
            borderRadius: "0%", 
            backgroundColor: colors.SECONDARY_COLOR, 
            left: 0,
            bottom: "1.75%",
            display: "flex",
            position: "fixed"}}           
            />
            <div style={{
            zIndex: -1,
            width: "27%",
            height: "3px",
            borderRadius: "0%", 
            backgroundColor: colors.SECONDARY_COLOR, 
            left: 0,
            bottom: "2.25%",
            display: "flex",
            position: "fixed"}}           
            />
        
        <div style={{
            zIndex: -1,
            width: "30%",
   
            aspectRatio: "1",
            borderRadius: "50%", 
            backgroundColor: colors.PRIMARY_COLOR, 
            left: 0,
            bottom: 0,
            display: "flex",
            position: "fixed"}}           
            />
        <div style={{
            zIndex: 2,
            backgroundColor: 'black',
            width: "30%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            bottom: ".25%"
        }}
        />
        <div style={{
            zIndex: 2,
            backgroundColor: 'black',
            width: "27%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            bottom: ".75%"
        }}
        />
        <div style={{
            zIndex: 2,
            backgroundColor: 'black',
            width: "24%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            bottom: "1.25%"
        }}
        />
        <div style={{
            zIndex: 2,
            backgroundColor: 'black',
            width: "21%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            bottom: "1.75%"
        }}
        />
        <div style={{
            zIndex: 2,
            backgroundColor: 'black',
            width: "18%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            bottom: "2.25%"
        }}
        />
        <div style={{
            zIndex: 2,
            backgroundColor: 'black',
            width: "15%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            bottom: "2.75%"
        }}
        />

        <div style={{
            zIndex: -2,
            backgroundColor: 'rgba(255,0,0,.5)',
            width: "55%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            top: "25%",
            right: 0,
            transform: " rotate(135deg)"
        }}
        />
        <div style={{
            zIndex: -2,
            backgroundColor: 'rgba(255,0,0,.5)',
            width: "45%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            top: "35%",
            right: 0,
            transform: " rotate(135deg)"
        }}
        />
        <div style={{
            zIndex: -2,
            backgroundColor: 'rgba(255,0,0,.5)',
            width: "35%",
            height: "3px",
            borderRadius: "0%",
            display: "flex",
            position: "fixed",
            top: "45%",
            right: 0,
            transform: "rotate(135deg)"
        }}
        />
   

      </BrowserRouter>
    );
    
  }

