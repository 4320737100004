import React from 'react'
import styles from './bigcss.css'
class Email extends React.Component{
    render(){
        return (
            <div >
                <h1>Email</h1>
                <p >bruketta.bailey@gmail.com</p>
            </div>
            
        );
    }
}
export default Email