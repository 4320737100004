import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
export function TitleText({titleText}) {
    const colors = {
        PRIMARY_COLOR: '#F17F2A',
        SECONDARY_COLOR: '#9d8f85',
        BORDER_COLOR: '#d1c9c3',
        BACKGROUND_COLOR: '#FAF4F0',
        GOOD_RED: '#f22929',
        GOOD_ORANGE: '#F17F2A'
    }
    //render(){
        return(
           
            <div style={{position: 'fixed', top: 15}}>
                <Typography style={{color: 'rgba(0,0,0,.6)', fontSize: 20}}>{titleText}</Typography>
                
            </div>
            
        );
   // }
}
export default TitleText;